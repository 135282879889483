import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Divider,
  Center,
  Text,
  AspectRatio,
} from "@chakra-ui/react"



export default function Servizi() {

  return(
    <Container maxW="8xl" minH="100vh">
      <Center>
        <Box>
          <Heading fontSize="70px" mt="50px">I nostri servizi</Heading>
          <Text mt="20px" align="center" fontWeight="600">Una piccola selezione dei nostri tagli contemporanei</Text>
          <Text align="center" fontWeight="600">Vieni a scoprire le nostre ultime creazioni sui nostri social!</Text>
        </Box>
      </Center>
    

      <Divider mt="50px"/>
      
      <SimpleGrid columns={[1,2]} spacing={[10]} p={["10px","50px"]} mt={["40px",0]}>
        <Servizio 
          title="Ice Blonde"
          image_url="https://firebasestorage.googleapis.com/v0/b/black-e-white-parrucchieri.appspot.com/o/ice_blonde.jpeg?alt=media&token=27eef9ec-a467-474e-a2b5-1bf53be60063"
        />
        
        <Servizio 
          title="Wave Cappuccino"
          image_url="https://firebasestorage.googleapis.com/v0/b/black-e-white-parrucchieri.appspot.com/o/wave_cappuccino.jpeg?alt=media&token=06e02477-6677-4a06-84e0-7ae7e7d97544"
        />
        
        <Servizio 
          title="Golden Waves"
          image_url="https://firebasestorage.googleapis.com/v0/b/black-e-white-parrucchieri.appspot.com/o/golden_wave.jpeg?alt=media&token=cb6920e8-0082-4448-83b3-8de2047fe776"
        />
        
        <Servizio 
          title="Caramel Bob"
          image_url="https://firebasestorage.googleapis.com/v0/b/black-e-white-parrucchieri.appspot.com/o/caramel_bob.png?alt=media&token=6c0a5a0b-88fe-4347-b877-0c3d1e6d650e"
        />
        
        <Servizio 
          title="Balayage"
          image_url="https://firebasestorage.googleapis.com/v0/b/black-e-white-parrucchieri.appspot.com/o/balayage.jpeg?alt=media&token=83701bab-4a4d-44cf-b5b2-ec747eb8c8d3"
        />
        
        <Servizio 
          title="E molto altro..."
          image_url="https://firebasestorage.googleapis.com/v0/b/black-e-white-parrucchieri.appspot.com/o/waves.jpg?alt=media&token=1e3f455a-b198-462a-be3e-51b1c4f39b11"
        />
      </SimpleGrid>


    </Container>
  )

}


interface ServiziProps {
  title: string;
  image_url: string;
}


function Servizio(props: ServiziProps) {

  const outerBoxStyles = {
    p: '3',
    background:
    `url(${props.image_url}) center/cover no-repeat`,
  }

  const innerBoxStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSize: 'full',
    color: 'white',
    textShadow: '0 0 20px black',
    fontWeight: 'bold',
    fontSize: ["24px","24px","26px","48px"],
    border: '2px solid gold'
  }

  return(
    <AspectRatio ratio={1/1}>
      <Box sx={outerBoxStyles}>
        <Box sx={innerBoxStyles} backdropFilter='auto' backdropBrightness="60%">
          {props.title}
        </Box>
      </Box>
    </AspectRatio>
  )
}