import {
  Box,
  Text,
  Stack,
  Container,
  Flex,
  Center,
  Button,
} from "@chakra-ui/react"

import {AiOutlineArrowRight, AiOutlineClockCircle, AiOutlinePhone} from "react-icons/ai";
import {MdPlace} from "react-icons/md"

export default function HeroSaloni() {


  return(

    <Box 
      bg="black"
      >

      <Container maxW="8xl" p="20px">

        <Center><Text fontSize="26px" fontWeight="800" color="white" mb="20px">I nostri saloni</Text></Center>

        <Stack direction={["column","column","column","row"]} justify="space-between">

          <Salone 
            name="DESENZANO DEL GARDA"
            address="Via Santa Maria, 37, 25015, Desenzano del Garda (BS)"
            address_url="https://goo.gl/maps/QBv7rA3wUUCkxAjy9"
            hour="Dalle 09:00 alle 18:00"
            phone="+39 030 991 1724"
            closed="Chiuso domenica e lunedì"
            image_url="https://firebasestorage.googleapis.com/v0/b/black-e-white-parrucchieri.appspot.com/o/salone_desenzano_del_garda.jpg?alt=media&token=97af67b8-63ad-4737-bd06-2baa440a4d76"
          />

          <Salone 
            name="PONTE SAN MARCO"
            address="Via Santi Faustino e Giovita, 1, 25011, Ponte San Marco (BS)"
            address_url="https://goo.gl/maps/rxnoFmCYzkNvX13K6"
            hour="Dalle 09:00 alle 18:00"
            phone="+39 030 963 6410"
            closed="Chiuso domenica e lunedì"
            image_url="https://firebasestorage.googleapis.com/v0/b/black-e-white-parrucchieri.appspot.com/o/salone_ponte_san_marco.jpeg?alt=media&token=04588cdf-28b3-4f60-8f4a-0d3ed0ad0467"
          />

        </Stack>

        <Center mt={["10px","40px"]}><Button color="white" variant="link" rightIcon={<AiOutlineArrowRight/>} as="a" href="/saloni">Scopri di più</Button></Center>

      </Container>
    </Box>
  )

}

interface SaloneProps {
  name: string;
  address: string;
  address_url: string;
  hour: string;
  phone: string;
  closed: string;
  image_url: string;
}


function Salone(props: SaloneProps) {

  const outerBoxStyle= {
    p: '10',
    background:
      `url(${props.image_url}) center/cover no-repeat`,
  }

  const innerBoxStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    boxSize: 'full',
    color: 'white',
    textShadow: '0 0 20px black',
    fontWeight: 'bold',
    fontSize: '28px',
  }

  return(

    <Stack direction={["column", "row"]} alignItems="center">

      <Box sx={outerBoxStyle} boxSize={["250px"]} as="a" href="/saloni">
        <Box sx={innerBoxStyle} backdropFilter='auto' backdropBlur='1px' boxSize={["100px","150px","250px"]}>
          <Text fontSize="28px">{props.name}</Text>
        </Box>
      </Box>

      <Box p={["20px","40px"]}>
        <Flex>
          <MdPlace color="white" />
          <Text noOfLines={[3, 3, 3]} maxW="200px" color="blue.200" ml="10px" as="a" href={props.address_url} target="_blank" rel="noopener noreferrer">{props.address}</Text> 
        </Flex>
        <Flex mt="10px">
          <AiOutlineClockCircle color="white"/>
          <Text ml="10px" color="white">{props.hour}</Text>
        </Flex>
        <Flex mt="10px">
          <AiOutlinePhone color="white"/>
          <Text ml="10px" color="white">{props.phone}</Text>
        </Flex>
        <Text as="u" color="white">{props.closed}</Text>
      </Box>

    </Stack>  

  )
}