import {
  Box,
  Text,
  Heading,
  Container,
  Divider,
} from "@chakra-ui/react"



export default function Footer () {
  return(
  <Box bg="black" >
    <Container maxW="8xl" minH="100vh" p="20px">
      <Box>
        <Heading color="white">Credits for all the materials used</Heading>
        <Divider mt="10px" mb="50px"/>
        <Text as="a" href="https://www.vecteezy.com/free-vector/hairdresser-tools" target="_blank" rel="noopener noreferrer" color="blue.400">Hairdresser Tools Vectors by Vecteezy</Text>
      </Box>
    </Container>
  </Box>
  
  )
}


