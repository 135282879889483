import {
  Box,
  Text,
  Image,
  Container,
  Heading,
  Divider,
  Flex,
  Center,
  Button,
} from "@chakra-ui/react"

import {AiOutlineArrowRight} from "react-icons/ai";

export default function About_Us() {

  return(
    
    <Container maxW="8xl" p={["20px","50px"]}>

      <Heading  fontSize={["32px","48px"]} fontWeight="800">Chi siamo</Heading>

      <Flex direction={["column", "row"]} mt="50px" align="center">
        <Image boxSize={["250px","250px","300px","500px"]} src="https://firebasestorage.googleapis.com/v0/b/black-e-white-parrucchieri.appspot.com/o/salone_desenzano_dettagli.jpeg?alt=media&token=b59e9b34-1b0d-417c-867a-f668397700db"></Image>
        <Box p={["10px","10px","20px","40px"]}>
          <Text fontSize={["16px","21px"]}  align="center"> Black e White è un gruppo appassionato di parrucchieri, abbiamo due saloni sia per uomo che per donna situati a Desenzano Del Garda e Ponte San Marco.</Text>
          <Text fontSize={["16px","21px"]} align="center">Il nostro gruppo si avvale di un ottimo staff, capace, competente e dalla lunga esperienza.</Text>
        </Box>
      </Flex>

      <Divider mt="50px"/>

      <Flex direction={["column", "row"]} mt="50px" align="center" gap={["50px"]}>
        <Box>
          <Text fontSize={["26px","32px"]} fontWeight="800" align="center">Qualità e cura</Text>
          <Text fontSize={["16px","19px"]} align="center">
             Siamo orgogliosi di fornire ai nostri clienti il miglior servizio e i migliori prodotti possibili
             grazie al supporto delle migliori aziende di settore. 
             </Text>
        </Box>
        <Box>
          <Text fontSize={["26px","32px"]} fontWeight="800" align="center">Il nostro ambiente</Text>
          <Text fontSize={["16px","19px"]} align="center">I saloni Black e White propongono ambienti moderni e curati. 
            Rilassati nell'atmosfera amichevole dei nostri locali mentre il nostro staff si prende cura di te.</Text>
        </Box>
      </Flex>

      <Divider mt="50px"/>

      <Flex direction={["column", "row"]} mt="50px" align="center">
        <Text fontSize={["16px","21px"]} p={["10px","10px","20px","40px"]} align="center">
        Il nostro parrucchiere è noto per gli stili contemporanei e promette sempre di rimanere aggiornato sulle ultime tendenze e mode.
        Poniamo grande attenzione al taglio dei capelli, allo styling e allo stile del salone. 
        Siamo orgogliosi di poterci prendere cura delle tue esigenze, il tutto in un'atmosfera amichevole e rilassante. </Text>
        <Image boxSize={["250px","250px","300px","500px"]} src="https://firebasestorage.googleapis.com/v0/b/black-e-white-parrucchieri.appspot.com/o/salone_desenzano_del_garda.jpg?alt=media&token=97af67b8-63ad-4737-bd06-2baa440a4d76"></Image>
      </Flex>
      
      <Divider mt="50px"/>

      <Box>
        <Center>
          <Text maxW="4xl" fontSize={["16px","18px"]} p={["10px","10px","20px","40px"]} mt="50px" align="center">
          Il parrucchiere non è solo un luogo dove farsi tagliare e acconciare i capelli, è anche un luogo per rilassarsi e ricaricarsi.
          </Text>
        </Center>
        <Center><Button color="blue.400" variant="link" rightIcon={<AiOutlineArrowRight/>} as="a" href="/servizi"><Text as="u">Scopri i nostri servizi</Text></Button></Center>
      </Box>
            

    </Container>

  )

}