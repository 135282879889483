import {
  Box,
} from "@chakra-ui/react"

import Hero from "../components/Hero/Hero"
import HeroSaloni from "../components/HeroSaloni"
import Cards from "../components/Cards/Cards"

export default function Home() {

  return(
    <Box>
      <Hero/>
      <HeroSaloni/>
      <Cards/>
    </Box>
    

  )

}