import {
  Box,
  Text,
  Container,
  Heading,
  Divider,
  Center,
  Flex,
  Image,
  Spacer,
  IconButton,
  Button,
} from "@chakra-ui/react"

import {AiFillPhone,AiOutlineArrowRight} from "react-icons/ai"
import {FaMapMarkerAlt} from "react-icons/fa"

export default function About_Us() {

  return(
    <Box bg="black" minHeight="100vh">
      <Container maxW="8xl">
        <Center>
          <Box w={["xl","4xl"]}>
            <Text color="white" mt="50px" align="center" fontSize={["42px","50px"]} fontWeight="700">Contattaci!</Text>

            <Box mt="50px">
            
              <Flex className="socialIcon" as="a" href="https://www.instagram.com/black_e_white_parrucchieri/" target="_blank" rel="noopener noreferrer">
                <Image src="https://cdn.icon-icons.com/icons2/1826/PNG/512/4202090instagramlogosocialsocialmedia-115598_115703.png" boxSize={["40px","70px"]}></Image>
                <Button color="white" p="10px" variant="link">Scrivici su Instagram<AiOutlineArrowRight color="white" /></Button>
              </Flex>

              <Flex mt="50px" className="socialIcon" as="a" href="https://www.facebook.com/blackewhitedesenzano/" target="_blank" rel="noopener noreferrer">
                <Image src="https://cdn.icon-icons.com/icons2/1826/PNG/512/4202110facebooklogosocialsocialmedia-115707_115594.png" boxSize={["40px","70px"]}></Image>
                <Button color="white" p="10px" variant="link">Scrivici su Facebook<AiOutlineArrowRight color="white" /></Button>    
              </Flex>

            </Box>

            <Flex mt="50px">
              <Text fontSize={["22px","28px"]} fontWeight="bold" color="white">Desenzano Del Garda</Text>
              <Spacer />
              <IconButton
                colorScheme='gray'
                aria-label='Nav to Desenzano'
                size='lg'
                icon={<FaMapMarkerAlt />}
                as="a" href="https://goo.gl/maps/QBv7rA3wUUCkxAjy9"
                target="_blank" rel="noopener noreferrer"
              />
              <IconButton
                colorScheme='gray'
                aria-label='Call Desenzano'
                size='lg'
                icon={<AiFillPhone />}
                as="a" href="tel:+390309911724"
                target="_blank" rel="noopener noreferrer"
                ml="20px"
              />
            </Flex>
            <Divider mt="50px"/>
            <Flex mt="50px">
              <Text fontSize={["22px","28px"]} fontWeight="bold" color="white">Ponte San Marco</Text>
              <Spacer />
              <IconButton
                colorScheme='gray'
                aria-label='Nav to Ponte'
                size='lg'
                icon={<FaMapMarkerAlt />}
                as="a" href="https://goo.gl/maps/rxnoFmCYzkNvX13K6"
                target="_blank" rel="noopener noreferrer"
              />
              <IconButton
                colorScheme='gray'
                aria-label='Call Ponte'
                size='lg'
                icon={<AiFillPhone />}
                as="a" href="tel:+390309636410"
                target="_blank" rel="noopener noreferrer"
                ml="20px"
              />
            </Flex>
          </Box>
        </Center>
        
      

      </Container>
    </Box>
    
    

  )

}