import {
  Box,
  Text,
  Stack,
  Image,
  Container,
  Heading,
  Center,
  Flex,
  Spacer,
  Button,
  SimpleGrid,
} from "@chakra-ui/react"

import {AiOutlineArrowRight} from "react-icons/ai"

import "./cards.css"

export default function Home() {

  return(
    <Container maxW="8xl" mt="50px" mb="50px">

      <Box id="social" bg="black" role="group" p="20px">
        <Center><Heading color="white" fontSize="26px" fontWeight="800"> I nostri social</Heading></Center>
        <Center mt="15px"><Text color="white" >Vieni a trovarci sulle nostre pagine Instagram e Facebook per scoprire tutti gli ultimi aggiornamenti !</Text></Center>
        <Center mt="15px">
          <Stack direction={["column", "row"]} gap={["15px","100px"]}>
            
            <Flex className="socialIcon" as="a" href="https://www.instagram.com/black_e_white_parrucchieri/" target="_blank" rel="noopener noreferrer">
              <Image src="https://cdn.icon-icons.com/icons2/1826/PNG/512/4202090instagramlogosocialsocialmedia-115598_115703.png" boxSize={["40px","70px"]}></Image>
              <Button color="white" p="10px" variant="link">Scopri il nostro Instagram<AiOutlineArrowRight color="white" /></Button>
            </Flex>

            <Flex className="socialIcon" as="a" href="https://www.facebook.com/blackewhitedesenzano/" target="_blank" rel="noopener noreferrer">
              <Image src="https://cdn.icon-icons.com/icons2/1826/PNG/512/4202110facebooklogosocialsocialmedia-115707_115594.png" boxSize={["40px","70px"]}></Image>
              <Button color="white" p="10px" variant="link">Scopri il nostro Facebook<AiOutlineArrowRight color="white" /></Button>    
            </Flex>

          </Stack>
        </Center>
      </Box>      


      <SimpleGrid columns={[1,2]} mt="50px" spacing={10}>

        <Card 
          title="Chi siamo"
          btn="Scopri la nostra storia" 
          image_url="https://firebasestorage.googleapis.com/v0/b/black-e-white-parrucchieri.appspot.com/o/shampoo.png?alt=media&token=bf01bcff-833d-407b-afd1-6a4dfe4c980f" 
          title_color="white" 
          btn_text_color="white" 
          bg_color="black"
          btn_bg_color="gray.700"
          link="/chi-siamo"
        />  

        <Card 
        title="Affidati alle nostre mani esperte"
        btn="Scopri i nostri servizi" 
        image_url="https://firebasestorage.googleapis.com/v0/b/black-e-white-parrucchieri.appspot.com/o/scissors.png?alt=media&token=55351563-f52f-4e78-a61e-8f37c570eea1" 
        title_color="white" 
        btn_text_color="white" 
        bg_color="blue.400"
        btn_bg_color="blue.500"
        link="/servizi"
        />

      </SimpleGrid>


    </Container>
  )

};


interface CardProps {
  title: string;
  btn: string;
  image_url: string;
  title_color: string;
  btn_text_color: string;
  bg_color: string;
  btn_bg_color: string;
  link: string;
}


function Card (props: CardProps) {
  
  return (
    <Flex id="card" p="20px" bg={props.bg_color} role="group" as="a" href={props.link} direction={["column", "row"]}>
        <Flex direction="column" align="start">
          <Heading color={props.title_color} fontSize={["26px"]} fontWeight="800">{props.title}</Heading>
          <Spacer />
          <Button id="card_btn" color={props.btn_text_color} variant="link" p="10px" _groupHover={{bg: props.btn_bg_color}}>{props.btn}<AiOutlineArrowRight color="white" /></Button>
        </Flex>
        <Spacer />
        <Box maxW="300px">
          <Box maxW={["250px","300px"]}><Image id="img" src={props.image_url}/></Box>
        </Box>
    </Flex>

  );
};