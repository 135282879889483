import theme from "./theme"
import {
  ChakraProvider,
  Text,
} from "@chakra-ui/react"

import { BrowserRouter as Router, Route, Routes} from "react-router-dom";

import CookieConsent from "react-cookie-consent";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import Navbar from "./components/Navbar"
import Footer from "./components/Footer"

import Home from "./pages/Home"
import Saloni from "./pages/Saloni/Saloni"
import About_Us from "./pages/About_Us"
import Contact_Us from "./pages/Contact_Us"
import Servizi from "./pages/Servizi"
import Credits from "./pages/Credits"
import Policy from "./pages/Policy"

export const App = () => (
  <ChakraProvider theme={theme}>
    <Navbar />
    <Router>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/saloni" element={<Saloni />}/>
        <Route path="/chi-siamo" element={<About_Us />}/>
        <Route path="/contatti" element={<Contact_Us />}/>
        <Route path="/servizi" element={<Servizi />}/>
        <Route path="/credits" element={<Credits />}/>
        <Route path="/policy" element={<Policy />}/>
      </Routes>
    </Router>
    <CookieConsent
      location="bottom"
      enableDeclineButton flipButtons
      style={{background:"#000", textAlign:"left"}}
      buttonStyle={{color:"#fff", background:"#4299E1"}}
      buttonText="Acconsento"
      expires={30}
      visible="byCookieValue"
      cookieValue="true"
      declineCookieValue="false"
      declineButtonText="Non Acconsento"
      onAccept={()=>{configureFirebase()}}
      >
       Questo sito usa cookie tecnici al fine di una miglior navigazione e consente anche l’invio di cookie di terze parti.
      <Text as="a" href="/policy" color="blue.400">Clicca qui</Text> per leggere l’informativa privacy nella quale è anche indicato come negare il consenso all'installazione di qualunque cookie.
       Cliccando il tasto "Acconsento" prestate il consenso all’uso dei cookie.
    </CookieConsent>
    <Footer/>
  </ChakraProvider>
)

function configureFirebase(){
    
  // TODO: Add SDKs for Firebase products that you want to use
  // https://firebase.google.com/docs/web/setup#available-libraries

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyCdXkGlmVZRS953IHulLD-1i31udpj5XJA",
    authDomain: "black-e-white-parrucchieri.firebaseapp.com",
    projectId: "black-e-white-parrucchieri",
    storageBucket: "black-e-white-parrucchieri.appspot.com",
    messagingSenderId: "206149166712",
    appId: "1:206149166712:web:d9c926468dadc7ed732f8d",
    measurementId: "G-KMKGR3DVNP"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
}
