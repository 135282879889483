import {
  Box,
  Text,
  Stack,
  Container,
  Heading,
  Divider,
  Flex,
  Spacer,
  Button,
} from "@chakra-ui/react"

import Slider from "../../components/Slider/Slider"


export default function Saloni() {

  const saloneDesenzanoImages = [
    {
      url: 'https://firebasestorage.googleapis.com/v0/b/black-e-white-parrucchieri.appspot.com/o/salone_desenzano_del_garda.jpg?alt=media&token=97af67b8-63ad-4737-bd06-2baa440a4d76',
      caption: 'SALONE DESENZANO DEL GARDA'
    },
    {
      url: 'https://firebasestorage.googleapis.com/v0/b/black-e-white-parrucchieri.appspot.com/o/salone_desenzano_dettagli.jpeg?alt=media&token=b59e9b34-1b0d-417c-867a-f668397700db',
      caption: 'ENTRATA'
    },
    {
      url: 'https://firebasestorage.googleapis.com/v0/b/black-e-white-parrucchieri.appspot.com/o/salone_desenzano_specchi.jpeg?alt=media&token=255579f1-b283-4171-bdc2-19efd57317f1',
      caption: 'POSTAZIONI'
    }
  ]

  const salonePonteSanMarcoImages = [
    {
      url: 'https://firebasestorage.googleapis.com/v0/b/black-e-white-parrucchieri.appspot.com/o/salone_ponte_san_marco.jpeg?alt=media&token=04588cdf-28b3-4f60-8f4a-0d3ed0ad0467',
      caption: 'SALONE PONTE SAN MARCO'
    },
    {
      url: 'https://firebasestorage.googleapis.com/v0/b/black-e-white-parrucchieri.appspot.com/o/salone_ponte_san_marco_entrata.jpeg?alt=media&token=02d0feae-12e1-431e-9599-9fb9bf057b85',
      caption: 'ENTRATA'
    },
    {
      url: 'https://firebasestorage.googleapis.com/v0/b/black-e-white-parrucchieri.appspot.com/o/salone_ponte_san_marco_dettagli.jpeg?alt=media&token=b7062eb9-26e4-4f69-bfb0-fe47c19d57d4',
      caption: 'POSTAZIONI'
    }
  ]


  return(
    <Box bg="black" minHeight="100vh">

      <Container maxW="8xl" p={["20px","50px"]}>

        <Salone
          title="Salone di Desenzano Del Garda"
          images={saloneDesenzanoImages}
          title_color="white" 
          description="Situato nel pieno centro di Desenzano Del Garda, a due passi dal lago. Vieni a trovarci! Recentemente ristrutturati, i diversi locali del salone, si dispongono come ambienti moderni, 
           arredati in stile minimal e dotati di attrezzature all’avanguardia per offrire solo il meglio al tuo benessere e ai tuoi capelli."
          google_maps="https://www.google.it/maps/place/Black+e+White/@45.4683331,10.5405031,17z/data=!3m1!4b1!4m5!3m4!1s0x47819444ac0c0d47:0x2f2cfb29f816d223!8m2!3d45.4683294!4d10.5426918?shorturl=1"
          phone_number="tel:+390309911724"
        />
        <Divider />
        <Salone
          title="Salone di Ponte San Marco"
          images= {salonePonteSanMarcoImages}
          title_color="white" 
          description="Vieni a trovarci al salone di Ponte San Marco, un ambiente moderno, arredato in stile minimal e dotati di attrezzature all’avanguardia per offrire solo il meglio al tuo benessere e ai tuoi capelli."
          google_maps="https://www.google.it/maps/place/Black+%26+White+Parrucchieri+Snc/@45.4749158,10.4083489,17z/data=!4m5!3m4!1s0x478199d9d29baac7:0x31e0473c4813ca26!8m2!3d45.4749121!4d10.4105376?shorturl=1"
          phone_number="tel:+390309636410"
        />
        
      </Container>

    </Box>
  )

}

interface SaloneProps {
  title: string;
  title_color: string;
  description: string;
  google_maps: string;
  phone_number: string;
  images: any;
}

 function Salone (props: SaloneProps) {

  return (
    <Flex p="20px" direction={["column", "row"]} mt="20px" mb="20px" align={["center","start"]}>
      <Box maxW={["250px","280px","300px","500px"]}><Slider images={props.images} /></Box>
      <Stack p="20px" alignItems={["center","end"]}>
        <Flex direction={["column"]}>
          <Heading color={props.title_color} fontSize={["32px","48px"]} fontWeight="800">{props.title}</Heading>
          <Text color="white" fontSize={["16px","18px"]}>{props.description}</Text>
        </Flex>
        <Spacer />
        <Flex direction={["column", "row"]} gap="20px">
          <Button size="lg" as="a" href={props.phone_number}>Chiama ora</Button>
          <Button colorScheme="blue" size="lg" as="a" href={props.google_maps} target="_blank" rel="noopener noreferrer">Indicazioni</Button>
        </Flex>
      </Stack >
      
    </Flex>
  );
};