import {
  Box,
  Text,
  Stack,
  Image,
  Container,
  Heading,
  Divider,
  Flex,
  Spacer,
  Center,
  Button,
  IconButton,
  ButtonGroup,
} from "@chakra-ui/react"

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'

import {MdCall } from "react-icons/md"
import { useDisclosure } from '@chakra-ui/react'

import {AiFillPhone} from "react-icons/ai"

export default function Hero() {

  const { isOpen, onOpen, onClose } = useDisclosure()

  return(
    <Container 
      maxW="8xl"
      mt={["20px","30px","50px","100px"]}
      mb={["20px","30px","50px","100px"]}
      >
      <Stack direction={["column","row"]} alignItems="center">
        <Box maxW="2xl">
          <Center>
            <Heading fontWeight="700" fontSize={["43px","64px"]}>Black e White</Heading>
          </Center>
          <Center>
            <Text fontWeight="300" fontSize={["20px","24px"]}>parrucchieri</Text>
          </Center>
          <Text mt={["20px","40px"]} fontWeight="300" fontSize={["14px","18px"]} lineHeight="19px" align="center">Due saloni per uomo e donna situati a Desenzano Del Garda e Ponte San Marco, esperti in tecniche di colorazione quali balayage
            e schiariture personalizzate per valorizzare il taglio dei tuoi capelli.</Text>
          <Center>
            <ButtonGroup mt="40px" >
              <Stack direction={["column","row"]} gap={["20px","60px"]}>
                <Button colorScheme="blue" size={["md","lg"]} leftIcon={<MdCall />} onClick={onOpen}>Prenota Ora</Button>
                <Button variant="outline" size={["md","lg"]} as="a" href="/servizi">I nostri servizi</Button>
              </Stack>  
            </ButtonGroup>
          </Center>
        </Box>
        <Spacer />
        <Box maxW="400px">
          <Image p={["30px","0px"]} src="https://firebasestorage.googleapis.com/v0/b/black-e-white-parrucchieri.appspot.com/o/black_e_white_logo_2048.png?alt=media&token=13dfff21-2309-4b78-8afe-ca98b284a1a9"/>
        </Box>
      </Stack>

      <Modal isOpen={isOpen} onClose={onClose} size={["lg","xl"]}>
        <ModalOverlay />
        <ModalContent bg="black">
          <ModalHeader color="white">Scegli il salone che vuoi contattare</ModalHeader>
          <ModalCloseButton color="white"/>
          <ModalBody color="white">
            <Flex>
              <Text fontSize="28px" fontWeight="bold">Desenzano Del Garda</Text>
              <Spacer />
              <Box>
                <IconButton
                  colorScheme='blue'
                  aria-label='Call Desenzano'
                  size='lg'
                  icon={<AiFillPhone />}
                  as="a" href="tel:+390309911724"
                />
              </Box>
            </Flex>
            <Divider mt="50px"/>
            <Flex mt="50px">
              <Text fontSize="28px" fontWeight="bold">Ponte San Marco</Text>
              <Spacer />
              <IconButton
                colorScheme='blue'
                aria-label='Call Ponte'
                size='lg'
                icon={<AiFillPhone />}
                as="a" href="tel:+390309636410"
              />
            </Flex>

          </ModalBody>

          <ModalFooter>
            <Button colorScheme='red' mt="50px" mr={0} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>




    </Container>

  )

}