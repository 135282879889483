import {
  Box,
  Text,
  Container,
  ButtonGroup,
  IconButton,
  Flex,
  Spacer,
} from "@chakra-ui/react"

import {FaFacebookF} from "react-icons/fa"
import {AiFillInstagram} from "react-icons/ai"


export default function Footer() {

  return(
    <Box bg="#e8e8e8">
      <Container maxW="8xl">
        <Box p="5px" ml="20px"><Text mt="5px" fontSize={["12px","18px"]} fontWeight="600">Black e White</Text></Box>
        <Flex ml="10px" mr="10px" p="5px" alignItems="center">
          <Text ml="10px" fontSize={["10px","12px"]}>P. Iva: 00638130989</Text>
          <Text ml="20px" fontSize={["10px","11px"]} as="a" href="/policy">Privacy Policy</Text>
          <Text ml="20px" fontSize={["10px","11px"]} as="a" href="/credits">Credits</Text>
          <Spacer />
          <Box> 
            <ButtonGroup ml="20px">
              <IconButton aria-label="Facebook" icon={<FaFacebookF />} as="a" href="https://www.facebook.com/blackewhitedesenzano/" target="_blank" rel="noopener noreferrer"/>
              <IconButton aria-label="Instagram" icon={<AiFillInstagram />} as="a" href="https://www.instagram.com/black_e_white_parrucchieri/" target="_blank" rel="noopener noreferrer"/>
            </ButtonGroup>
          </Box>
        </Flex>
      </Container>
    </Box>  

  )

}