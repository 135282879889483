import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

import {
  Text,
  Center
} from "@chakra-ui/react"

import "./Slider.css"

export default function Slider(props: any) {

  const fadeImages = props.images
   
  const buttonStyle = {
    width: "30px",
    border: '0px',
    margin: "5px",
  }

  const properties = {
    nextArrow: <button style={{ ...buttonStyle }}><svg xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 16 16">
    <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z"/>
  </svg></button>,
    
    prevArrow: <button style={{ ...buttonStyle }}><svg xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 16 16">
        <path d="M16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12zm-4.5-6.5H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5a.5.5 0 0 0 0-1z"/>
      </svg></button>,
    duration: 6000,
    transitionDuration:500,
    easing: 'ease'
  }
  
  return (
    <div className="slide-container">
      <Fade {...properties}>
        {fadeImages.map((fadeImage: any, index: any) => (
          <div className="each-fade" key={index}>
            <div className="image-container">
              <img src={fadeImage.url} />
            </div>
            <Center><Text mt="5px" fontSize="14px" fontWeight="600" color="white">{fadeImage.caption}</Text></Center>
          </div>
        ))}
      </Fade>
    </div>
  )
  
}